<template>
  <section class="choice-member" v-loading="membersLoading">
    <div class="search">
      <TextInput
        v-model="keyword"
        placeholder="회원 이름 또는 전화번호 검색"
        prefix-icon="el-icon-search"
        round
        @keyup.enter.native="handleChangeKeyword"
      />
    </div>
    <div class="table-box">
      <el-table
        class="member-table"
        highlight-current-row
        style="width:100%"
        ref="singleTable"
        @current-change="handleCurrentChange"
        :data="members"
      >
        <el-table-column label="이름" prop="name" width="135" align="center">
          <div class="name-wrapper" slot-scope="scope">
            <span>{{ scope.row.name }}</span>
            <vaccine-image v-if="scope.row.vaccination_yn === 'Y'" />
          </div>
        </el-table-column>
        <el-table-column label="전화번호" prop="`mobile`" width="170" align="center">
          <template slot-scope="scope">
            {{ getMemberMobile(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="락커" prop="`mobile`" width="200" align="center">
          <template v-slot="scope">
            <ul class="locker-list">
              <li v-for="lockers in scope.row.lockers" :key="lockers.id">
                <div class="locker-list__title">{{ lockers.no }}. {{ lockers.name }}</div>
                <div class="ticket-list__tags">
                  <el-tag size="medium" type="info">
                    {{ lockers.end_on | date }}
                  </el-tag>
                </div>
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="" width="270" align="center" />
      </el-table>

      <el-pagination
        @current-change="handleChangePage"
        :current-page.sync="pageParams.page"
        layout="prev, pager, next"
        :total="total"
      />
    </div>
    <div class="footer-button-group" v-if="!membersLoading">
      <el-button v-if="lockerData.edit !== 2" type="basic" @click="goToPrev">
        이전
      </el-button>
      <el-button type="basic" @click="$emit('close')">
        취소
      </el-button>
    </div>
  </section>
</template>

<script>
import VaccineImage from '@/components/shared/VaccineImage.vue';
const DEFAULT_FILTER_VALUES = {
  is_account: null,
  searchFor: 'NAME|MOBILE',
};
export default {
  components: { VaccineImage },
  data() {
    return {
      filterValues: { ...DEFAULT_FILTER_VALUES },
      membersLoading: false,
      keyword: null,
      data: { type: 'T', addCount: null },
      members: [],
      membersSelected: null,
      currentRow: null,
      pageParams: { page: 1, limit: 10 },
    };
  },
  created() {
    this.getMembers(this.filterValues);
  },

  computed: {
    lockerData() {
      return this.$store.getters['locker/formData'];
    },
  },

  methods: {
    async getMembers(filter) {
      try {
        this.membersLoading = true;
        const params = this.$utils.mapParamsForMemberList(filter);
        const res = await this.$utils.getApis({
          params: {
            ...params,
            page: this.pageParams.page,
            limit: this.pageParams.limit,
            is_mobile: true,
            with: 'profile;lockers',
          },
          name: 'memberGetAll',
          once: true,
        });

        this.members = res.data;
        this.total = res.meta.total;
      } catch (error) {
        this.members = [];
      } finally {
        this.membersLoading = false;
      }
    },
    validateKey(e) {
      if (!e.key.replace(/\D/g, '')) e.preventDefault();
    },
    handleChangeKeyword() {
      this.filterValues = { ...DEFAULT_FILTER_VALUES, keyword: this.keyword };
      this.getMembers(this.filterValues);
    },
    async handleCurrentChange(val) {
      this.currentRow = val;
      const data = {
        currentStep: 3,
        type: null,
        userName: val.name,
        memberId: val.id,
        vaccination: val.vaccination_yn,
      };

      await this.$store.dispatch('locker/assignLocker', data);
    },
    getMemberMobile(row) {
      return this.canViewMembersMobile ? this.$filters.mobile(row.mobile) : this.$filters.mobileMask(row.mobile);
    },
    goToPrev() {
      return this.$store.dispatch('locker/assignLocker', { currentStep: 1 });
    },
    handleChangePage(values) {
      this.pageParams.page = values;
      this.getMembers(this.filterValues);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-pagination {
  padding: 30px 0px;
  text-align: center;
}

::v-deep .el-table__row {
  height: 40px;
  cursor: pointer;
}
.search {
  width: 230px;
  float: right;
  margin-right: 30px;
}
.table-box {
  padding: 0 30px;
}
.choice-member {
  height: 600px;
}
.member-table {
  margin: 15px 0 0 0 0;
  overflow-y: auto;
  &::before {
    display: none;
  }
}
.footer-button-group {
  float: right;
}
.locker-list {
  li {
    display: flex;
    margin: 3px 6px 3px 0;
  }

  &__title {
    @include ellipsis;
    @include flex(row, center);
    width: 100px;
  }
}
.name-wrapper {
  span {
    text-align: left;
  }
}
</style>
