<template>
  <section class="message-list">
    <div class="message-list__header">
      <div class="message-list__header__selects">
        <el-select
          :value="currentFilter.status"
          filterable
          :class="{ 'is-filtered': !!currentFilter.status }"
          @change="value => setFilter('status', value)"
        >
          <el-option v-for="option in RESERVE_TYPE_OPTIONS" :key="option.label" :value="option.value" :label="option.label">
            {{ option.label }}
          </el-option>
        </el-select>
        <el-date-picker
          type="daterange"
          range-separator="-"
          format="yyyy. M. d."
          value-format="yyyy-MM-dd"
          :value="[currentFilter.start_date, currentFilter.end_date]"
          @input="value => setFilter('dateRange', value)"
          :clearable="false"
          required
        />
        <p v-if="!loading">발송건수 : {{ total }}건</p>
      </div>

      <TextInput
        style="width: 200px;"
        v-model="keyword"
        placeholder="회원명 or 제목"
        prefix-icon="el-icon-search"
        round
        @enter="() => setFilter('keyword', keyword)"
      />
    </div>

    <el-table
      v-loading="loading"
      :data="messages"
      :row-style="{ cursor: 'pointer', height: '40px' }"
      @row-click="handleClickRow"
      fit
    >
      <el-table-column label="발송시간" align="center" min-width="150">
        <template slot-scope="scope">
          {{ scope.row.send_on | datetime }}
        </template>
      </el-table-column>

      <el-table-column align="center" min-width="80">
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.status" size="mini">예약</el-tag>
          <el-tag v-else-if="scope.row.status === 'canceled'" size="mini" type="danger">예약취소</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="메시지" header-align="center" min-width="294">
        <template slot-scope="scope">
          <p class="message-list__title">
            {{ scope.row.title }}
          </p>
        </template>
      </el-table-column>

      <el-table-column label="받는 사람" align="center" min-width="180">
        <template slot-scope="scope">
          {{ targetsName(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column label="발송건수" align="center" min-width="80">
        <template slot-scope="scope"> {{ scope.row.targets_count | comma }}건 </template>
      </el-table-column>

      <el-table-column min-width="120" align="center" header-align="center">
        <template slot="header">
          <p>
            처리현황
          </p>
          <span style="font-size: 10px;">
            <span style="color: #69de35;">성공</span>
            <span> / </span>
            <span style="color: #fd9f9f;">실패</span>
            <span> / </span>
            <span style="color: #cbcbcb;">처리 중</span>
          </span>
        </template>

        <template slot-scope="scope">
          <span style="color: #69de35;">{{ scope.row.successCount }}</span>
          <span> / </span>
          <!-- 예약취소시 readyCount로 대체 -->
          <span style="color: #fd9f9f;">{{
            scope.row.status === 'canceled' ? scope.row.readyCount : scope.row.failedCount
          }}</span>
          <span> / </span>
          <span style="color: #cbcbcb;">{{ scope.row.status === 'canceled' ? 0 : scope.row.readyCount }}</span>
        </template>
      </el-table-column>

      <el-table-column label="발송 결과" min-width="120" align="center">
        <template slot-scope="scope">
          <PlainButton
            v-if="scope.row.status && scope.row.status !== 'canceled'"
            size="small"
            @click.native.stop="handleClickDetail(scope.row)"
            >결과 보기</PlainButton
          >
          <PlainButton
            size="small"
            style="margin-right: 14px;"
            v-if="!scope.row.status"
            @click.native.stop="handleClickEdit(scope.row.id)"
            :disabled="!canUpdateAppPushMessage"
            >수정</PlainButton
          >
          <PlainButton
            :type="canUpdateAppPushMessage ? 'danger' : 'default'"
            size="small"
            v-if="!scope.row.status"
            @click.native.stop="handleClickCancel(scope.row)"
            :disabled="!canUpdateAppPushMessage"
            >취소</PlainButton
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="message-list__pagination"
      @current-change="page => setFilter('page', page)"
      :current-page.sync="page"
      :page-size="DEFAULT_LIMIT"
      layout="prev, pager, next"
      :total="total"
    />
    <MessageDetailList :show="showDetailModal" @close="handleDetailListClose" :detailData="detailData" />
  </section>
</template>

<script>
import moment from 'moment';
import MessageDetailList from '@/components/Modals/MessageDetailList';

const DEFAULT_LIMIT = 10;
const FILTERS = {
  status: {
    key: 'status',
  },
  start: {
    key: 'start_date',
    default: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
  },
  end: {
    key: 'end_date',
    default: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
  },
  page: {
    key: 'page',
    default: 1,
  },
  keyword: {
    key: 'keyword',
  },
};

const RESERVE_TYPE_OPTIONS = [
  { value: undefined, label: '메시지 상태 전체' },
  { value: 'reservation', label: '예약' },
  { value: 'sended', label: '발송' },
];

export default {
  components: { MessageDetailList },
  data() {
    return {
      keyword: '',
      loading: undefined,
      messages: [],
      total: 0,
      page: 1,
      showDetailModal: false,
      detailData: null,
    };
  },

  created() {
    this.RESERVE_TYPE_OPTIONS = RESERVE_TYPE_OPTIONS;
    this.DEFAULT_LIMIT = DEFAULT_LIMIT;
    this.keyword = this.currentFilter.keyword;
  },

  computed: {
    currentFilter() {
      return this.$utils.uriQuery.getInitializedQuery(this, FILTERS);
    },
  },

  mounted() {
    this.getMessages();
  },

  methods: {
    setFilter(option, value) {
      if (option === 'dateRange') {
        this.$utils.uriQuery.setQuery(this, { start_date: value[0], end_date: value[1] });
        return;
      }
      this.$utils.uriQuery.setQuery(this, { [option]: value });
    },

    async getMessages() {
      this.loading = true;
      const params = {
        limit: this.DEFAULT_LIMIT,
        ...this.currentFilter,
      };

      try {
        const res = await this.$api.message.getAll(params);
        this.total = res.data.total;
        this.messages = res.data.data;
        this.page = res.data.current_page;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    targetsName(row) {
      const length = row.targets.length;
      if (length === 0) {
        return '0 명';
      }
      const name = row.targets[0].with_trashed_member.name;
      return length === 1 ? name : `${name} 외 ${length - 1}명`;
    },

    handleClickRow(row) {
      this.$router.push({
        path: `/message/${row.id}`,
        query: this.currentFilter,
      });
    },

    handleClickDetail(row) {
      this.detailData = row;
      return (this.showDetailModal = true);
    },

    handleClickEdit(messageId) {
      this.$router.push(`/message/${messageId}/edit`);
    },

    async handleClickCancel(row) {
      const title = '예약 메시지 취소';
      const sendOn = this.moment(row.send_on).format('llll');
      const count = this.$filters.comma(row.targets_count);
      const message = `${sendOn} ${row.title} ${count}건의 예약 메시지를 취소하시겠습니까?`;

      const confirmed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (confirmed) {
        try {
          await this.$api.message.update({
            id: row.id,
            status: 'canceled',
          });
          this.$utils.notify.success(this, null, '예약 메시지가 취소되었습니다.');
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.getMessages();
        }
      }
    },

    handleDetailListClose(close) {
      this.showDetailModal = close;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-list {
  @include flex(column, center, center);

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    &__selects {
      @include flex(row, center, flex-start);
      /deep/ .el-select {
        width: 143px;
        margin-right: 8px;
      }

      /deep/ .el-input__inner {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 40px;
        padding: 0 15px;
        color: #5d646b;
      }

      /deep/ .el-date-editor {
        width: 230px;
      }

      /deep/ .el-range-separator {
        line-height: 3;
      }

      /deep/ .el-range-input:nth-child(2) {
        margin-left: 10px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }

      p {
        color: #64aeff;
        font-weight: bold;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  &__title {
    @include ellipsis;
  }

  &__pagination {
    margin-top: 20px;
  }

  .is-filtered {
    color: $color-primary;

    /deep/ .el-input__inner {
      color: $color-primary;
    }
  }
}
</style>
